@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.testimonial-blockV4 {
  font-family: "Lato", sans-serif;
  background-color: #fafafc; }
  @media only screen and (max-width: 1198px) {
    .testimonial-blockV4 {
      min-width: 640px; } }
  @media only screen and (min-width: 1199px) {
    .testimonial-blockV4 {
      min-width: 1040px; } }
  .testimonial-blockV4 .wrapper2 {
    text-align: center; }
    @media only screen and (max-width: 1198px) {
      .testimonial-blockV4 .wrapper2 {
        padding-bottom: 50px; } }
    @media only screen and (min-width: 1199px) {
      .testimonial-blockV4 .wrapper2 {
        padding-top: 0;
        padding-bottom: 40px; } }
  .testimonial-blockV4 .wrapper1 {
    text-align: center; }
  .testimonial-blockV4 .title {
    color: #61b05f; }
    @media only screen and (max-width: 1198px) {
      .testimonial-blockV4 .title {
        font-size: 39px;
        margin-bottom: 40px; } }
    @media only screen and (min-width: 1199px) {
      .testimonial-blockV4 .title {
        font-size: 30px;
        margin-bottom: 15px; } }
  .testimonial-blockV4 .testimonial-list {
    position: relative;
    font-family: "Open Sans", sans-serif; }
    @media only screen and (min-width: 1199px) {
      .testimonial-blockV4 .testimonial-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item {
      display: inline-block;
      text-align: left;
      text-align-last: left; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item {
          width: auto;
          margin-top: 34.1px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item {
          width: 447px;
          margin-top: 55px;
          vertical-align: top; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-link {
      background: #FFF;
      display: block;
      position: relative;
      box-shadow: 0 0 10px 0 rgba(164, 166, 179, 0.38);
      box-sizing: border-box; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-link {
          padding: 42.7px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-link {
          height: 160px;
          padding: 25px 25px 0 25px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-main {
      display: block;
      position: relative; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-main {
          padding-left: 89.5px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-main {
          height: 42px;
          padding-left: 57px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-avatar {
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-avatar {
          width: 70px;
          height: 70px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-avatar {
          width: 41px;
          height: 41px; } }
      .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-avatar img {
        display: block;
        width: 100%;
        height: 100%; }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info {
      width: 100%;
      position: relative; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info {
          display: inline-block;
          margin-top: -6px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info {
          display: block;
          padding-top: 2px;
          padding-bottom: 2px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-top {
      width: 100%;
      position: relative;
      display: block;
      color: #91959d; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-top {
          font-size: 20.5px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-top {
          font-size: 13px;
          line-height: 17px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-name {
      color: #365899;
      font-weight: bold; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-name {
          display: block;
          margin-top: -3px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-group-name {
      color: #365899; }
    @media only screen and (min-width: 1199px) {
      .testimonial-blockV4 .testimonial-list .testimonial-list-item-aux {
        margin: 0 4px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-star {
      height: 17px;
      display: inline-block;
      background: #5890ff;
      color: #FFF;
      position: relative; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-star {
          height: 30.7px;
          line-height: 30.7px;
          padding: 0 12.4px 0 10.2px;
          border-radius: 15.4px;
          position: absolute;
          margin-left: 10px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-star {
          padding-left: 7px;
          padding-right: 19px;
          border-radius: 25px; } }
      .testimonial-blockV4 .testimonial-list .testimonial-list-item-star svg {
        fill: #fff; }
        @media only screen and (max-width: 1198px) {
          .testimonial-blockV4 .testimonial-list .testimonial-list-item-star svg {
            top: 7px;
            right: 12px;
            width: 17.1px;
            height: 17.1px; } }
        @media only screen and (min-width: 1199px) {
          .testimonial-blockV4 .testimonial-list .testimonial-list-item-star svg {
            position: absolute;
            display: block;
            top: 3px;
            right: 5px;
            height: 10px;
            width: 10px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-bottom {
      position: relative;
      color: #90949c; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-bottom {
          font-size: 18.8px;
          margin-top: 2px;
          display: inline-block; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-bottom {
          width: 100%;
          font-size: 11px;
          line-height: 17px; } }
      .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-bottom .svg-icon {
        fill: #90949c; }
        @media only screen and (max-width: 1198px) {
          .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-bottom .svg-icon {
            position: relative;
            top: 1.5px;
            left: 1px;
            width: 17.1px;
            height: 17.1px; } }
        @media only screen and (min-width: 1199px) {
          .testimonial-blockV4 .testimonial-list .testimonial-list-item-user-info-bottom .svg-icon {
            position: relative;
            top: 1px;
            left: 1px;
            height: 10px;
            width: 10px; } }
    .testimonial-blockV4 .testimonial-list .testimonial-list-item-post {
      color: #2f2f2f;
      font-size: 12px;
      line-height: 17px;
      display: block; }
      @media only screen and (max-width: 1198px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-post {
          font-size: 24px;
          line-height: 1.64;
          padding-top: 20px; } }
      @media only screen and (min-width: 1199px) {
        .testimonial-blockV4 .testimonial-list .testimonial-list-item-post {
          font-size: 12px;
          line-height: 1.5;
          padding-top: 15px; } }
  .testimonial-blockV4 .more-reviews {
    text-align: center; }
    @media only screen and (max-width: 1198px) {
      .testimonial-blockV4 .more-reviews {
        margin-top: 60px; } }
    @media only screen and (min-width: 1199px) {
      .testimonial-blockV4 .more-reviews {
        margin-top: 40px; } }
    @media only screen and (max-width: 1198px) {
      .testimonial-blockV4 .more-reviews img {
        width: 513px;
        height: 75px; } }
    @media only screen and (min-width: 1199px) {
      .testimonial-blockV4 .more-reviews img {
        width: 333px;
        height: 48px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
