//@include font-face("rockwell", font-files("fonts/Rockwell.woff","fonts/Rockwell.ttf","fonts/Rockwell.svg"),"fonts/Rockwell.eot");
//@include font-face("rockwellb", font-files("fonts/Rockwell-Bold.woff","fonts/Rockwell-Bold.ttf","fonts/Rockwell-Bold.svg"),"fonts/Rockwell-Bold.eot");

@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Rockwell.eot'); /* IE9 Compat Modes */
  src: local('Rockwell'),
  url('/fonts/Rockwell.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Rockwell.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Rockwell.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Rockwell.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Rockwell-Bold.eot'); /* IE9 Compat Modes */
  src: local('Rockwell-Bold'), local('RockwellBold'),
  url('/fonts/Rockwell-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Rockwell-Bold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Rockwell-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Rockwell-Bold.svg#OpenSans') format('svg'); /* Legacy iOS */
}

$rockwell: "Rockwell", serif;
$rockwellb: "RockwellBold", serif;
$opensans: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif;
$robotoslab: 'Roboto Slab', serif;
$poppins: 'Poppins', sans-serif;
$assistant: 'Assistant', sans-serif;
