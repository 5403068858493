@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.personal-nerd-blockV4 .wrapper2 {
  text-align: center;
  position: relative; }
  @media only screen and (max-width: 1198px) {
    .personal-nerd-blockV4 .wrapper2 {
      padding-top: 85.3px;
      padding-bottom: 85.3px; } }
  @media only screen and (min-width: 1199px) {
    .personal-nerd-blockV4 .wrapper2 {
      padding-top: 90px;
      padding-bottom: 70px; } }
  .personal-nerd-blockV4 .wrapper2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%; }
    @media only screen and (max-width: 1198px) {
      .personal-nerd-blockV4 .wrapper2:after {
        width: 512px;
        height: 1.7px;
        background-color: #edeef2;
        margin-left: -256px; } }
    @media only screen and (min-width: 1199px) {
      .personal-nerd-blockV4 .wrapper2:after {
        width: 800px;
        height: 1px;
        background-color: rgba(212, 221, 230, 0.5);
        margin-left: -400px; } }

.personal-nerd-blockV4 .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #303240; }
  @media only screen and (max-width: 1198px) {
    .personal-nerd-blockV4 .title {
      font-size: 47.8px;
      line-height: 1.39; } }
  @media only screen and (min-width: 1199px) {
    .personal-nerd-blockV4 .title {
      font-size: 28px;
      line-height: 1.39; } }

.personal-nerd-blockV4 .subtitle {
  font-family: "Assistant", sans-serif;
  font-weight: bold;
  line-height: 1.56;
  color: #6c6f80; }
  @media only screen and (max-width: 1198px) {
    .personal-nerd-blockV4 .subtitle {
      font-size: 27.3px;
      margin-top: 24.7px; } }
  @media only screen and (min-width: 1199px) {
    .personal-nerd-blockV4 .subtitle {
      font-size: 16px;
      margin: 15px 20px 0; } }

@media only screen and (max-width: 1198px) {
  .personal-nerd-blockV4 .colleges-list {
    width: 519px;
    height: 324px;
    margin: 50px auto 34px;
    background: url("./img/handhelds/colleges-mobile.png");
    background-size: cover; } }
  @media only screen and (max-width: 1198px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 1198px) and (min-resolution: 192dpi), only screen and (max-width: 1198px) and (min--moz-device-pixel-ratio: 2), only screen and (max-width: 1198px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 1198px) and (min-device-pixel-ratio: 2), only screen and (max-width: 1198px) and (min-resolution: 2dppx) {
    .personal-nerd-blockV4 .colleges-list {
      background-image: url("./img/handhelds/colleges-mobile@2x.png");
      background-size: cover; } }

@media only screen and (min-width: 1199px) {
  .personal-nerd-blockV4 .colleges-list {
    width: 848px;
    height: 60px;
    margin: 25px auto 68.3px;
    background: url("./img/wide-screens/colleges-desktop@2x.png") 0 0 no-repeat;
    background-size: cover;
    /*
      @include retina(x2) {
        background: url('./img/wide-screens/colleges-desktop@2x.png') 0 0 no-repeat;
        background-size: cover;
      }
*/ } }

.personal-nerd-blockV4 .personal-nerd-btn {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #0079ff;
  transition: background-color .2s ease; }
  .personal-nerd-blockV4 .personal-nerd-btn:hover {
    background-color: #0069d9; }
  @media only screen and (max-width: 1198px) {
    .personal-nerd-blockV4 .personal-nerd-btn {
      width: 512px;
      line-height: 76.8px;
      border-radius: 100px;
      margin-top: 43.7px;
      font-size: 30px; } }
  @media only screen and (min-width: 1199px) {
    .personal-nerd-blockV4 .personal-nerd-btn {
      width: 220px;
      line-height: 45px;
      border-radius: 100px;
      font-size: 18px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
