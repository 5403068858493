@media only screen and (max-width: 1198px) {
  .header .holder {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px; } }

@media only screen and (min-width: 1199px) {
  .header .holder {
    padding-top: 23px;
    padding-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media only screen and (min-width: 1199px) {
  .header .header-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; } }

@media only screen and (max-width: 1198px) {
  .header .logo-link {
    display: inline-block; } }

@media only screen and (min-width: 1199px) {
  .header .logo-link {
    display: block; } }

.header .logo-icon {
  display: block; }
  @media only screen and (max-width: 1198px) {
    .header .logo-icon {
      margin: 0 auto; } }

@media only screen and (max-width: 1198px) {
  .header .input.input-select__countries {
    position: absolute;
    top: 14px;
    right: 20px; } }

@media only screen and (min-width: 1199px) {
  .header .input.input-select__countries {
    margin-left: 60px; } }

.header_1 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  @media only screen and (max-width: 1198px) {
    .header_1 .logo-icon {
      width: 209.9px;
      height: 76.8px; } }
  @media only screen and (min-width: 1199px) {
    .header_1 .logo-icon {
      width: 82.4px;
      height: 28.7px; } }

.header_2 {
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #dadce6; }
  @media only screen and (max-width: 1198px) {
    .header_2 .logo-icon {
      width: 127.8px;
      height: 44.8px; } }
  @media only screen and (min-width: 1199px) {
    .header_2 .logo-icon {
      width: 70.3px;
      height: 25px; } }

.header_4 {
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #dadce6; }
  .header_4 .holder {
    position: relative; }
  .header_4 .logo-link {
    margin: 0 auto; }
  @media only screen and (max-width: 1198px) {
    .header_4 .logo-icon {
      width: 127.8px;
      height: 44.8px; } }
  @media only screen and (min-width: 1199px) {
    .header_4 .logo-icon {
      width: 70.3px;
      height: 25px; } }
  .header_4 .back-link {
    position: absolute; }
    @media only screen and (max-width: 1198px) {
      .header_4 .back-link {
        top: 43px;
        left: 20px; } }
    @media only screen and (min-width: 1199px) {
      .header_4 .back-link {
        top: 21px;
        left: 20px; } }

.header_3 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  @media only screen and (max-width: 1198px) {
    .header_3 .holder {
      padding-top: 50px;
      padding-left: 34px;
      padding-right: 34px;
      text-align: left; } }
  @media only screen and (min-width: 1199px) {
    .header_3 .holder {
      padding-top: 40px; } }
  @media only screen and (max-width: 1198px) {
    .header_3 .logo-icon {
      width: 150.62px;
      height: 50.83px;
      margin: 0; } }
  @media only screen and (min-width: 1199px) {
    .header_3 .logo-icon {
      width: 88.6px;
      height: 29.9px; } }
