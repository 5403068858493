@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.ddwn-container {
  position: relative; }
  .ddwn-container.fluid {
    width: 100%; }
  .ddwn-container .ddwn-content {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border: solid 1px #dadce6;
    border-radius: 5px;
    font-family: 'Assistant', sans-serif; }
    .ddwn-container .ddwn-content.width_md {
      min-width: 200px; }
    .ddwn-container .ddwn-content.width_block {
      width: 100%; }
    .ddwn-container .ddwn-content .wrapper {
      padding: 10px 15px;
      cursor: default; }
    .ddwn-container .ddwn-content.content_right {
      left: auto;
      right: 0; }
    .ddwn-container .ddwn-content ul {
      padding: 0;
      margin: 0;
      list-style: none; }
