@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.form-reg {
  max-width: 600px;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #dadce6;
  font-family: 'Poppins', sans-serif;
  padding: 35px 50px;
  color: #303240;
  margin: 0 auto;
  box-sizing: border-box; }
  .form-reg * {
    box-sizing: border-box; }
  @media only screen and (max-width: 1198px) {
    .form-reg {
      width: 100%; } }
  .form-reg .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.39;
    text-align: center;
    margin-bottom: 6px; }
  .form-reg .sub-title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #8a8c99;
    margin-bottom: 30px; }
  .form-reg .form-box label {
    font-family: 'Assistant', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #8a8c99;
    display: block;
    margin-bottom: 7px; }
  .form-reg .form-box .input,
  .form-reg .form-box textarea,
  .form-reg .form-box input {
    font-family: 'Assistant', sans-serif;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #dadce6;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 15px 10px; }
    .form-reg .form-box .input.block,
    .form-reg .form-box textarea.block,
    .form-reg .form-box input.block {
      display: block;
      width: 100%; }
    .form-reg .form-box .input.is_error,
    .form-reg .form-box textarea.is_error,
    .form-reg .form-box input.is_error {
      background-color: #fffbd2;
      border: solid 1px #ff3363; }
    .form-reg .form-box .input::-webkit-input-placeholder,
    .form-reg .form-box textarea::-webkit-input-placeholder,
    .form-reg .form-box input::-webkit-input-placeholder {
      color: #a4a6b3; }
    .form-reg .form-box .input::-moz-placeholder,
    .form-reg .form-box textarea::-moz-placeholder,
    .form-reg .form-box input::-moz-placeholder {
      color: #a4a6b3; }
    .form-reg .form-box .input:-moz-placeholder,
    .form-reg .form-box textarea:-moz-placeholder,
    .form-reg .form-box input:-moz-placeholder {
      color: #a4a6b3; }
    .form-reg .form-box .input:-ms-input-placeholder,
    .form-reg .form-box textarea:-ms-input-placeholder,
    .form-reg .form-box input:-ms-input-placeholder {
      color: #a4a6b3; }
  .form-reg .form-box textarea {
    height: 100px;
    width: 100%; }
  .form-reg .form-box .btn {
    width: 220px;
    height: 45px;
    border-radius: 100px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    background-color: #0079ff;
    transition: background-color .2s ease;
    display: block;
    margin: 0 auto; }
    .form-reg .form-box .btn:hover {
      background-color: #0069d9; }
    .form-reg .form-box .btn:disabled {
      background-color: #edeef2;
      color: #a4a6b3; }
    @media only screen and (max-width: 1198px) {
      .form-reg .form-box .btn {
        margin: 0 auto; } }
  .form-reg .form-box .form-box-field {
    position: relative; }
    .form-reg .form-box .form-box-field:not(.no-padd) {
      margin-bottom: 25px; }
    .form-reg .form-box .form-box-field .symbol {
      font-family: 'Poppins', sans-serif;
      margin: 0 10px;
      color: #8a8c99; }
    .form-reg .form-box .form-box-field .error {
      display: none; }
    .form-reg .form-box .form-box-field.is-error input {
      background-color: #fffbd2;
      border: solid 1px #ff3363; }
    .form-reg .form-box .form-box-field.is-error .error {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      color: #ff3333; }
  .form-reg .form-box .desc-box {
    border-top: solid 1px #edeef2;
    border-bottom: solid 1px #edeef2;
    padding: 15px 0;
    font-size: 12px;
    line-height: 1.56;
    color: #8a8c99;
    margin-bottom: 30px; }
  .form-reg .form-box .add-cv {
    font-size: 14px;
    font-weight: bold;
    color: #8a8c99;
    font-family: 'Assistant', sans-serif;
    margin-bottom: 15px; }
    .form-reg .form-box .add-cv a {
      color: #0079ff;
      transition: color .2s ease;
      cursor: pointer; }
      .form-reg .form-box .add-cv a:hover {
        color: #0069d9; }
  .form-reg .form-box .file-box {
    margin-bottom: 30px; }
  .form-reg .form-box .file-delete {
    display: flex; }
  .form-reg .form-box .icon-delete {
    width: 14px;
    height: 14px;
    margin-right: 10px; }
  .form-reg .form-box .file-name {
    font-family: 'Assistant', sans-serif;
    font-size: 14px;
    color: #303240; }
  .form-reg .btn-dropdown {
    font-family: 'Assistant', sans-serif;
    cursor: pointer;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #dadce6;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 15px 10px;
    position: relative;
    color: #a4a6b3; }
    .form-reg .btn-dropdown.block {
      display: block;
      width: 100%; }
    .form-reg .btn-dropdown .icon-triangle {
      position: absolute;
      width: 10px;
      height: 6px;
      top: 50%;
      margin-top: -3px;
      right: 15px; }
      .form-reg .btn-dropdown .icon-triangle.is-open {
        transform: rotate(180deg); }
  .form-reg .select-item {
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 3px;
    margin-bottom: 5px; }
    .form-reg .select-item.active, .form-reg .select-item:hover {
      background-color: #e6f2ff;
      transition: background-color .2s; }
  .form-reg .row {
    justify-content: space-between; }
