html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%; }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
 * ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
 * ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit; }
  a:active, a:hover {
    outline: 0; }

/* Text-level semantics
 * ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 * ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
 * ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
 * ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }
  button:focus,
  input:focus,
  optgroup:focus,
  select:focus,
  textarea:focus {
    outline: none; }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
  cursor: pointer;
  padding: 0;
  background-color: transparent; }
  button::-moz-focus-inner {
    border: 0; }

button, input, textarea {
  border: none; }

input {
  box-sizing: border-box; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  padding: 0; }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
 * ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

i, address {
  font-style: normal; }

ol, ul {
  list-style: none; }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v17-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans-v17-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-regular.woff") format("woff"), url("../fonts/open-sans-v17-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v17-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans-v17-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-700.woff") format("woff"), url("../fonts/open-sans-v17-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/lato-v16-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato-v16-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v16-latin-regular.woff2") format("woff2"), url("../fonts/lato-v16-latin-regular.woff") format("woff"), url("../fonts/lato-v16-latin-regular.ttf") format("truetype"), url("../fonts/lato-v16-latin-regular.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/lato-v16-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato-v16-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v16-latin-700.woff2") format("woff2"), url("../fonts/lato-v16-latin-700.woff") format("woff"), url("../fonts/lato-v16-latin-700.ttf") format("truetype"), url("../fonts/lato-v16-latin-700.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/lato-v16-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Lato Black"), local("Lato-Black"), url("../fonts/lato-v16-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v16-latin-900.woff2") format("woff2"), url("../fonts/lato-v16-latin-900.woff") format("woff"), url("../fonts/lato-v16-latin-900.ttf") format("truetype"), url("../fonts/lato-v16-latin-900.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v9-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"), url("../fonts/roboto-slab-v9-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v9-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v9-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v9-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v9-latin-regular.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v9-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Slab Bold"), local("RobotoSlab-Bold"), url("../fonts/roboto-slab-v9-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v9-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v9-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v9-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v9-latin-700.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/assistant-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Assistant"), local("Assistant-Regular"), url("../fonts/assistant-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/assistant-v4-latin-regular.woff2") format("woff2"), url("../fonts/assistant-v4-latin-regular.woff") format("woff"), url("../fonts/assistant-v4-latin-regular.ttf") format("truetype"), url("../fonts/assistant-v4-latin-regular.svg#Assistant") format("svg");
  /* Legacy iOS */ }

/* assistant-700 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/assistant-v4-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Assistant Bold"), local("Assistant-Bold"), url("../fonts/assistant-v4-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/assistant-v4-latin-700.woff2") format("woff2"), url("../fonts/assistant-v4-latin-700.woff") format("woff"), url("../fonts/assistant-v4-latin-700.ttf") format("truetype"), url("../fonts/assistant-v4-latin-700.svg#Assistant") format("svg");
  /* Legacy iOS */ }

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/poppins-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-regular.woff2") format("woff2"), url("../fonts/poppins-v8-latin-regular.woff") format("woff"), url("../fonts/poppins-v8-latin-regular.ttf") format("truetype"), url("../fonts/poppins-v8-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Medium"), local("Poppins-Medium"), url("../fonts/poppins-v8-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-500.woff2") format("woff2"), url("../fonts/poppins-v8-latin-500.woff") format("woff"), url("../fonts/poppins-v8-latin-500.ttf") format("truetype"), url("../fonts/poppins-v8-latin-500.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/poppins-v8-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-600.woff2") format("woff2"), url("../fonts/poppins-v8-latin-600.woff") format("woff"), url("../fonts/poppins-v8-latin-600.ttf") format("truetype"), url("../fonts/poppins-v8-latin-600.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/poppins-v8-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-700.woff2") format("woff2"), url("../fonts/poppins-v8-latin-700.woff") format("woff"), url("../fonts/poppins-v8-latin-700.ttf") format("truetype"), url("../fonts/poppins-v8-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */ }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v17-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans-v17-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-regular.woff") format("woff"), url("../fonts/open-sans-v17-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v17-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans-v17-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-700.woff") format("woff"), url("../fonts/open-sans-v17-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/lato-v16-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato-v16-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v16-latin-regular.woff2") format("woff2"), url("../fonts/lato-v16-latin-regular.woff") format("woff"), url("../fonts/lato-v16-latin-regular.ttf") format("truetype"), url("../fonts/lato-v16-latin-regular.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/lato-v16-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato-v16-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v16-latin-700.woff2") format("woff2"), url("../fonts/lato-v16-latin-700.woff") format("woff"), url("../fonts/lato-v16-latin-700.ttf") format("truetype"), url("../fonts/lato-v16-latin-700.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/lato-v16-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Lato Black"), local("Lato-Black"), url("../fonts/lato-v16-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v16-latin-900.woff2") format("woff2"), url("../fonts/lato-v16-latin-900.woff") format("woff"), url("../fonts/lato-v16-latin-900.ttf") format("truetype"), url("../fonts/lato-v16-latin-900.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v9-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"), url("../fonts/roboto-slab-v9-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v9-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v9-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v9-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v9-latin-regular.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v9-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Slab Bold"), local("RobotoSlab-Bold"), url("../fonts/roboto-slab-v9-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v9-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v9-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v9-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v9-latin-700.svg#RobotoSlab") format("svg");
  /* Legacy iOS */ }

/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/assistant-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Assistant"), local("Assistant-Regular"), url("../fonts/assistant-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/assistant-v4-latin-regular.woff2") format("woff2"), url("../fonts/assistant-v4-latin-regular.woff") format("woff"), url("../fonts/assistant-v4-latin-regular.ttf") format("truetype"), url("../fonts/assistant-v4-latin-regular.svg#Assistant") format("svg");
  /* Legacy iOS */ }

/* assistant-700 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/assistant-v4-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Assistant Bold"), local("Assistant-Bold"), url("../fonts/assistant-v4-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/assistant-v4-latin-700.woff2") format("woff2"), url("../fonts/assistant-v4-latin-700.woff") format("woff"), url("../fonts/assistant-v4-latin-700.ttf") format("truetype"), url("../fonts/assistant-v4-latin-700.svg#Assistant") format("svg");
  /* Legacy iOS */ }

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/poppins-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-regular.woff2") format("woff2"), url("../fonts/poppins-v8-latin-regular.woff") format("woff"), url("../fonts/poppins-v8-latin-regular.ttf") format("truetype"), url("../fonts/poppins-v8-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Medium"), local("Poppins-Medium"), url("../fonts/poppins-v8-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-500.woff2") format("woff2"), url("../fonts/poppins-v8-latin-500.woff") format("woff"), url("../fonts/poppins-v8-latin-500.ttf") format("truetype"), url("../fonts/poppins-v8-latin-500.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/poppins-v8-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-600.woff2") format("woff2"), url("../fonts/poppins-v8-latin-600.woff") format("woff"), url("../fonts/poppins-v8-latin-600.ttf") format("truetype"), url("../fonts/poppins-v8-latin-600.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/poppins-v8-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/poppins-v8-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v8-latin-700.woff2") format("woff2"), url("../fonts/poppins-v8-latin-700.woff") format("woff"), url("../fonts/poppins-v8-latin-700.ttf") format("truetype"), url("../fonts/poppins-v8-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */ }

.cookie-popup {
  position: fixed;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 999; }
  @media only screen and (max-width: 1198px) {
    .cookie-popup {
      left: 0;
      right: 0;
      bottom: 0;
      padding: 32px;
      box-shadow: 6.4px 6.4px 12.8px 0 rgba(37, 38, 93, 0.08), -6.4px -6.4px 12.8px 0 rgba(37, 38, 93, 0.08); } }
  @media only screen and (min-width: 1199px) {
    .cookie-popup {
      left: 20px;
      bottom: 20px;
      width: 512px;
      height: 188px;
      border-radius: 8px;
      padding: 24px;
      box-shadow: 4px 4px 8px 0 rgba(37, 38, 93, 0.08), -4px -4px 8px 0 rgba(37, 38, 93, 0.08); } }
  .cookie-popup .svg-icon {
    display: block; }
  @media only screen and (max-width: 1198px) {
    .cookie-popup .ic_checkbox, .cookie-popup .ic_checkbox_checked, .cookie-popup .ic_checkbox_disabled {
      width: 32px;
      height: 32px; }
    .cookie-popup .ic_cross {
      width: 38.4px;
      height: 38.4px; } }
  .cookie-popup .message {
    font-family: "Lato", sans-serif;
    color: #4E526B;
    font-size: 12px;
    line-height: 20px; }
    @media only screen and (max-width: 1198px) {
      .cookie-popup .message {
        font-size: 19.2px;
        line-height: 32px; } }
  .cookie-popup .link {
    font-weight: 700;
    text-decoration-line: underline;
    white-space: nowrap; }
  .cookie-popup .buttons {
    display: flex;
    align-self: stretch; }
    @media only screen and (max-width: 1198px) {
      .cookie-popup .buttons {
        gap: 19.2px;
        flex-direction: column-reverse;
        margin-top: 25.6px; } }
    @media only screen and (min-width: 1199px) {
      .cookie-popup .buttons {
        gap: 12px;
        margin-top: auto; } }
  .cookie-popup .button {
    font-family: 'Aleo', serif;
    display: flex; }
    @media only screen and (max-width: 1198px) {
      .cookie-popup .button {
        font-size: 28.8px;
        height: 70.4px;
        padding: 0 16px; } }
    @media only screen and (min-width: 1199px) {
      .cookie-popup .button {
        font-size: 18px;
        height: 44px;
        padding: 0 10px; } }
    .cookie-popup .button.type1 {
      color: #24A652; }
      @media only screen and (max-width: 1198px) {
        .cookie-popup .button.type1 {
          flex-basis: 0; } }
    .cookie-popup .button.type2 {
      background: #FFF;
      color: #24A652;
      flex-grow: 1; }
      @media only screen and (max-width: 1198px) {
        .cookie-popup .button.type2 {
          border-radius: 6.4px;
          border: 1.6px solid #24A652; } }
      @media only screen and (min-width: 1199px) {
        .cookie-popup .button.type2 {
          border-radius: 4px;
          border: 1px solid #24A652;
          flex-basis: 0; } }
    .cookie-popup .button.type3 {
      background: #24A652;
      color: #FFF;
      flex-grow: 1; }
      @media only screen and (max-width: 1198px) {
        .cookie-popup .button.type3 {
          border-radius: 6.4px; } }
      @media only screen and (min-width: 1199px) {
        .cookie-popup .button.type3 {
          border-radius: 4px;
          flex-basis: 0; } }
  .cookie-popup .titleBlock {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center; }
  .cookie-popup .title {
    color: #4E526B;
    font-family: "Lato", sans-serif;
    font-weight: 700; }
    @media only screen and (max-width: 1198px) {
      .cookie-popup .title {
        font-size: 28.8px;
        line-height: 38.4px; } }
    @media only screen and (min-width: 1199px) {
      .cookie-popup .title {
        font-size: 18px;
        line-height: 24px; } }
  @media only screen and (max-width: 1198px) {
    .cookie-popup .checkboxBlock {
      display: grid;
      gap: 19.2px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 25.6px; } }
  @media only screen and (min-width: 1199px) {
    .cookie-popup .checkboxBlock {
      display: flex;
      justify-content: space-between;
      margin-top: 20px; } }
  .cookie-popup .checkboxItem {
    display: flex;
    align-items: center; }
  .cookie-popup .hiddenCheckbox {
    display: none; }
  .cookie-popup .checkboxLabel {
    display: flex;
    cursor: pointer; }
    @media only screen and (max-width: 1198px) {
      .cookie-popup .checkboxLabel {
        gap: 12.8px; } }
    @media only screen and (min-width: 1199px) {
      .cookie-popup .checkboxLabel {
        gap: 8px; } }
  .cookie-popup .checkboxTitle {
    color: #4E526B;
    font-family: "Lato", sans-serif;
    font-weight: 700; }
    @media only screen and (max-width: 1198px) {
      .cookie-popup .checkboxTitle {
        font-size: 22.4px;
        line-height: 28.8px;
        margin-top: 1.6px; } }
    @media only screen and (min-width: 1199px) {
      .cookie-popup .checkboxTitle {
        font-size: 14px;
        line-height: 18px;
        margin-top: 1px; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
* {
  box-sizing: border-box; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

@media only screen and (max-width: 1198px) {
  .desktop-only {
    display: none !important; } }

@media only screen and (min-width: 1199px) {
  .mobile-only {
    display: none !important; } }

html {
  height: 100%;
  background: #fff; }

body {
  min-height: 100%;
  position: relative;
  font-family: "Rockwell", serif; }

html, body, #root, .page {
  height: 100%; }

.wrapper1 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1198px) {
    .wrapper1 {
      width: 640px; } }
  @media only screen and (min-width: 1199px) {
    .wrapper1 {
      width: 1040px; } }

.wrapper2 {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; }
  @media only screen and (max-width: 1198px) {
    .wrapper2 {
      width: 640px;
      padding-left: 34px;
      padding-right: 34px; } }
  @media only screen and (min-width: 1199px) {
    .wrapper2 {
      width: 1040px;
      padding-left: 30px;
      padding-right: 30px; } }

.strong {
  font-weight: bold; }

.btn {
  width: 220px;
  height: 45px;
  border-radius: 100px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #0079ff;
  transition: background-color .2s ease;
  display: block;
  margin: 0 auto; }

.btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #fff;
  background-color: #0079ff;
  transition: background-color .2s ease; }
  @media only screen and (max-width: 1198px) {
    .btn2 {
      height: 76.5px;
      border-radius: 170px;
      font-size: 30.6px; } }
  @media only screen and (min-width: 1199px) {
    .btn2 {
      height: 45px;
      border-radius: 100px;
      font-size: 18px; } }

.h1 {
  font-family: "Poppins", sans-serif;
  line-height: 1.44;
  font-weight: 700;
  color: #00bf5c; }
  @media only screen and (max-width: 1198px) {
    .h1 {
      font-size: 54.6px; } }
  @media only screen and (min-width: 1199px) {
    .h1 {
      font-size: 32px; } }

.h3 {
  color: #31354f;
  font-size: 26px;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif; }

.h5 {
  font-family: "Assistant", sans-serif;
  color: #303240; }
  @media only screen and (max-width: 1198px) {
    .h5 {
      font-size: 34.1px;
      font-weight: 700;
      line-height: 1.45; } }
  @media only screen and (min-width: 1199px) {
    .h5 {
      font-size: 20px;
      line-height: 1.1; } }

.h6 {
  color: #31354f;
  letter-spacing: .24px;
  font-size: 18px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif; }

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  transition: background .3s ease,border .3s ease,color .3s ease; }

.button_1 {
  background-color: #0079ff; }
  .button_1:hover {
    background-color: #0069d9; }

.a {
  color: #24a652; }
  .a:hover {
    color: #27b659; }

.hr {
  background: #edeef2;
  height: 1px;
  max-width: 815px;
  margin: 0 auto; }

.button__type6 {
  background: #24a652;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  height: 45px;
  padding: 0 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: 0.5px; }
  .button__type6:hover {
    background: #27b659; }
  .button__type6[disabled] {
    background: #a4e4ba; }
  @media only screen and (max-width: 1198px) {
    .button__type6.button__type6_mob_full_length {
      width: 100%; } }

.become .banner {
  background: url("../img/become-top-bg@2x.png") no-repeat;
  background-size: cover;
  color: #fff;
  min-height: 500px;
  display: flex;
  font-family: 'Poppins', sans-serif;
  align-items: center; }
  @media only screen and (max-width: 1198px) {
    .become .banner {
      text-align: center;
      min-width: 640px; } }
  .become .banner .v4-header-title {
    font-size: 66px;
    line-height: 1.45;
    margin-bottom: 21px;
    font-weight: 600; }
  .become .banner .v4-header-subtitle-top {
    font-size: 40px;
    line-height: .8; }
  .become .banner .v4-header-subtitle-bottom {
    font-size: 22px; }

.become .become-content {
  padding: 50px 0;
  position: relative;
  background-color: #fafcff; }
  .become .become-content .wrapper1 {
    position: relative;
    z-index: 1; }
  .become .become-content:before, .become .become-content:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%; }
    @media only screen and (max-width: 1198px) {
      .become .become-content:before, .become .become-content:after {
        display: none; } }
  .become .become-content:before {
    left: 0;
    background: url("../img/become-bg-left.png") no-repeat;
    background-size: 100% auto;
    width: 290px; }
  .become .become-content:after {
    right: 0;
    background: url("../img/become-bg-right.png") no-repeat;
    background-size: 100% auto;
    width: 350px; }

.become .title-success {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #00bfbc;
  text-align: center;
  margin-bottom: 20px; }

.become .title-base {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.56;
  text-align: center;
  color: #6c6f80;
  font-family: 'Assistant', sans-serif;
  margin-bottom: 40px; }
