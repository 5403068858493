@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.featured-blockV4 {
  background-color: #fff; }
  .featured-blockV4 .wrapper2 {
    text-align: center; }
    @media only screen and (max-width: 1198px) {
      .featured-blockV4 .wrapper2 {
        padding-top: 129.7px; } }
    @media only screen and (min-width: 1199px) {
      .featured-blockV4 .wrapper2 {
        padding-top: 70px;
        padding-bottom: 50px; } }
  .featured-blockV4 .quote {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: #00bfbc; }
    @media only screen and (max-width: 1198px) {
      .featured-blockV4 .quote {
        position: relative;
        font-size: 56.3px;
        line-height: 1.24; } }
    @media only screen and (min-width: 1199px) {
      .featured-blockV4 .quote {
        font-size: 35px; } }
    .featured-blockV4 .quote .mark {
      color: #8a8c99; }
      @media only screen and (max-width: 1198px) {
        .featured-blockV4 .quote .mark {
          position: absolute;
          font-size: 78.5px;
          letter-spacing: 1.6px; }
          .featured-blockV4 .quote .mark.open {
            top: -10px;
            left: 20px; }
          .featured-blockV4 .quote .mark.close {
            bottom: -18px;
            right: -2px; } }
      @media only screen and (min-width: 1199px) {
        .featured-blockV4 .quote .mark {
          font-size: 50px;
          letter-spacing: 0.9px;
          margin: 0 7px; }
          .featured-blockV4 .quote .mark.open {
            vertical-align: -10px; } }
  .featured-blockV4 .quote-author {
    font-family: "Assistant", sans-serif;
    color: #6c6f80; }
    @media only screen and (max-width: 1198px) {
      .featured-blockV4 .quote-author {
        font-size: 27.3px;
        letter-spacing: 0.5px;
        margin-top: 47.7px; } }
    @media only screen and (min-width: 1199px) {
      .featured-blockV4 .quote-author {
        font-size: 16px;
        letter-spacing: 0.3px;
        color: #6c6f80;
        margin-top: -15px; } }
