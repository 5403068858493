@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.faq-block {
  font-family: "Lato", sans-serif; }
  @media only screen and (max-width: 1198px) {
    .faq-block {
      min-width: 640px; } }
  @media only screen and (min-width: 1199px) {
    .faq-block {
      min-width: 1040px; } }
  .faq-block .title {
    font-weight: 600;
    color: #303240;
    text-align: center; }
    @media only screen and (max-width: 1198px) {
      .faq-block .title {
        font-size: 48px;
        line-height: 1.39;
        margin-bottom: 60px; } }
    @media only screen and (min-width: 1199px) {
      .faq-block .title {
        font-size: 28px;
        margin-bottom: 40px; } }
  @media only screen and (max-width: 1198px) {
    .faq-block .wrapper1 {
      padding-top: 74px;
      padding-bottom: 76px; } }
  @media only screen and (min-width: 1199px) {
    .faq-block .wrapper1 {
      padding-top: 45px;
      padding-bottom: 70px; } }
  @media only screen and (max-width: 1198px) {
    .faq-block .filler {
      display: none; } }
  @media only screen and (min-width: 1199px) {
    .faq-block .filler {
      height: 0;
      transition: height .5s ease; } }
  .faq-block .collapse.in {
    height: auto;
    margin-bottom: 30px;
    padding: 0 10px 10px 10px; }
  .faq-block .collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease 0s; }
  @media only screen and (min-width: 1199px) {
    .faq-block .accordion-group {
      width: 22%;
      display: inline-block;
      vertical-align: top; } }
  .faq-block .accordion-heading {
    position: relative; }
    @media only screen and (max-width: 1198px) {
      .faq-block .accordion-heading {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 1199px) {
      .faq-block .accordion-heading {
        padding-bottom: 30px; } }
    .faq-block .accordion-heading .accordion-toggle {
      cursor: pointer; }
      @media only screen and (max-width: 1198px) {
        .faq-block .accordion-heading .accordion-toggle {
          display: block;
          text-align: center; } }
      @media only screen and (min-width: 1199px) {
        .faq-block .accordion-heading .accordion-toggle {
          padding-left: 20px;
          padding-right: 20px; } }
  .faq-block .accordion-inner {
    border-radius: 6px;
    position: relative;
    box-shadow: 0 1px 3px 0 rgba(138, 140, 153, 0.14);
    background-color: #ffffff;
    border: solid 1px #dadce6;
    padding: 20px 25px 20px 30px; }
    @media only screen and (max-width: 1198px) {
      .faq-block .accordion-inner {
        padding: 20px 25px 20px 30px;
        border-radius: 2px; } }
  @media only screen and (max-width: 1198px) {
    .faq-block .accordion-msg {
      font-size: 29px;
      line-height: 44px;
      color: #454a60;
      overflow: hidden; } }
  @media only screen and (min-width: 1199px) {
    .faq-block .accordion-msg {
      font-size: 16px;
      line-height: 24px;
      color: #454a60;
      overflow: hidden; } }
  @media only screen and (max-width: 1198px) {
    .faq-block .accordion .question {
      font-size: 37px;
      color: #6f789f;
      text-decoration: underline;
      white-space: pre-line;
      transition: color .5s ease; }
      .faq-block .accordion .question.active {
        color: #61b05f;
        text-decoration: none; } }
  @media only screen and (min-width: 1199px) {
    .faq-block .accordion {
      display: flex;
      position: relative;
      justify-content: space-between; }
      .faq-block .accordion .accordion-heading {
        text-align: center;
        position: relative; }
        .faq-block .accordion .accordion-heading .question {
          font-size: 20px;
          color: #6f79a1;
          transition: color .5s ease; }
          .faq-block .accordion .accordion-heading .question:not(.active) {
            border-bottom: 2px solid #ACB2C6; }
            .faq-block .accordion .accordion-heading .question:not(.active):hover {
              color: #0069d9;
              text-decoration: underline; }
    .faq-block .accordion-body {
      position: absolute;
      left: 0;
      width: 100%; } }

.faq-block.fbnew-faq-block .accordion-toggle.question {
  font-family: 'Poppins', sans-serif;
  color: #0084ff;
  text-decoration: none;
  display: block;
  min-height: 70px; }
  @media only screen and (max-width: 1198px) {
    .faq-block.fbnew-faq-block .accordion-toggle.question {
      padding-left: 145px;
      padding-right: 145px;
      font-size: 32px;
      text-decoration: none; } }
  @media only screen and (min-width: 1199px) {
    .faq-block.fbnew-faq-block .accordion-toggle.question {
      font-size: 16px;
      border-bottom: none !important; } }
  .faq-block.fbnew-faq-block .accordion-toggle.question.active {
    color: #303240;
    text-decoration: none;
    display: block; }
    .faq-block.fbnew-faq-block .accordion-toggle.question.active:before {
      content: '';
      position: absolute;
      z-index: 100;
      top: calc(100% + 22px);
      left: 50%;
      margin-left: -7px;
      width: 15px;
      height: 15px;
      border-left: 1px solid #DADCE6;
      border-top: 1px solid #DADCE6;
      transform: rotate(50grad);
      background: #fff; }
      @media only screen and (min-width: 1199px) {
        .faq-block.fbnew-faq-block .accordion-toggle.question.active:before {
          top: calc(100% - 8px); } }
