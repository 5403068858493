@media only screen and (min-width: 1199px) {
  .back-link .arrow-left-icon {
    width: 30px;
    height: 30px;
    fill: #24a652; } }

@media only screen and (max-width: 1198px) {
  .back-link .arrow-left-icon {
    width: 51px;
    height: 51px;
    fill: #31354f; } }
