@import "../../assets/css/mixins.scss";
@import "../../assets/css/fonts.scss";

.slider1 {
  position: relative;

  .carousel-inner {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.go-slider-block {
  font-family: $lato;
  background-color: #fafcff;
  position: relative;

  @include background-fix;

  @include respond-to(wide-screens) {
    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
    }
    &:before {
      left: 0;
      top: 0;
      width: 410px;
      height: 100%;
      background: url(./img/slider-bg1.png) no-repeat;
      background-size: 100% auto;
    }
    &:after {
      right: 0;
      bottom: 0;
      width: 380px;
      height: 100%;
      background: url(./img/slider-bg2.png) no-repeat;
      background-size: 100% auto;
    }
  }

  /*
  a {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  */

  .wrapper1 {
    position: relative;
    z-index: 1;

    @include respond-to(handhelds) {
      padding-top: 46px;
    }
    @include respond-to(wide-screens) {
      padding-top: 45px;
    }
  }
  .go-slider-title {
    text-align: center;
    font-weight: 600;
    color: #303240;
    @include respond-to(handhelds) {
      font-size: 48px;
      line-height: 1.39;
      padding: 0 65px 75px;
    }
    @include respond-to(wide-screens) {
      font-size: 28px;
      margin-bottom: 13px;
    }
    &.type1 {
      @include respond-to(handhelds) {
        padding: 0 0 34px;
      }
      @include respond-to(wide-screens) {
        margin-bottom: 13px;
      }
    }
  }

  .go-slider-subtitle {
    text-align: center;
    color: #6c6f80;
    @include respond-to(handhelds) {
      font-size: 27px;
      line-height: 1.56;
      margin: 0 15px 35px;
    }
    @include respond-to(wide-screens) {
      font-size: 16px;
      margin: 0 0 44px;
    }
  }

  .carousel-indicators {
    display: flex;
    @include respond-to(handhelds) {
      padding: 0 0 125px;
      position: relative;
      justify-content: space-around; //space-between;
    }
    @include respond-to(wide-screens) {
      padding: 0 20px;
      justify-content: center;//space-around; //space-between;
    }
    li {
      text-align: center;
      cursor: pointer;
      @include respond-to(handhelds) {
        width: 107px;
        height: 96px;
        padding-bottom: 14px;
        border-bottom: 7px solid transparent;
        box-sizing: border-box;
      }
      @include respond-to(wide-screens) {
        width: 150px;
        height: 119px;
        position: relative;
        &:not(:first-child) {
          margin-left: 60px;
        }
      }
      svg {
        &:first-child {
          display: inline-block;
        }
        &:first-child + svg {
          display: none;
        }
      }
      .indicator-title {
        text-align: center;
        white-space: nowrap;
        position: relative;
        @include respond-to(handhelds) {
          font-size: 48px;
          font-weight: 900;
          color: transparent;
          padding-top: 28px;
          padding-bottom: 38px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          //background-color: transparent;
          border-top: 2px solid #D7D9E2;
        }
        @include respond-to(wide-screens) {
          font-size: 15px;
          color: #454b61;
          padding-top: 17px;
        }
      }
      &.active {
        @include respond-to(handhelds) {
          border-bottom-color: #0084ff;
          .indicator-title {
            color: #0084ff;
            //background-color: #fff;
          }
        }
        @include respond-to(wide-screens) {
          &:before, &:after {
            content: '';
            position: absolute;
            left: 50%;
          }
          &:before {
            bottom: 6px;
            margin-left: -63px;
            width: 126px;
            height: 6px;
            background-color: #0084ff;
          }
          &:after {
            content: ' ';
            margin-left: -6px;
            bottom: 0;
            border-top: 7px solid #0084ff;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
          }
        }
        svg {
          &:first-child {
            display: none;
          }
          &:first-child + svg {
            display: inline-block;
          }
        }
        .indicator-title {
          color: #0084ff;
        }
      }
    }
    .go-slide-ind1 {
      @include respond-to(handhelds) {
        padding-left: 15px;
      }
    }
    .go-slide-ind2 {
      @include respond-to(handhelds) {
        padding-left: 5px;
      }
    }
    .go-slide-ind1, .go-slide-ind2 {
      svg {
        @include respond-to(handhelds) {
          width: 68px;
          height: 68px;
        }
        @include respond-to(wide-screens) {
          width: 55px;
          height: 55px;
        }
      }
    }
    .go-slide-ind3 {
      svg {
        @include respond-to(handhelds) {
          width: 82px;
          height: 68px;
        }
        @include respond-to(wide-screens) {
          width: 67px;
          height: 55px;
        }
      }
    }
    .go-slide-ind4 {
      svg {
        @include respond-to(handhelds) {
          width: 107px;
          height: 68px;
        }
        @include respond-to(wide-screens) {
          width: 86px;
          height: 55px;
        }
      }
    }
  }
  .iphone-wrapper {
    overflow-y: hidden;
    margin: 0 auto;
    position: relative;

    @include respond-to(handhelds) {
      width: 513px;
      height: 853px;
    }
    @include respond-to(wide-screens) {
      width: 366px;
      height: 610px;
      margin-top: 35px;
    }

    .iphone-img {
      display: block;
      @include respond-to(handhelds) {
        width: 513px;
        height: 853px;
      }
      @include respond-to(wide-screens) {
        width: 366px;
        height: 610px;
      }
    }

    .carousel-inner {
      position: absolute;
      overflow: hidden;
      @include respond-to(handhelds) {
        top: 105px;
        left: 25px;
        width: 464px;//286px;
        img {
          width: 464px;//286px;
          height: auto;
        }
      }
      @include respond-to(wide-screens) {
        top: 70px;
        left: 18px;
        width: 330px;
        img {
          width: 330px;
          height: auto;
        }
      }
    }
  }
  .carousel {
    @include respond-to(handhelds) {
      padding-top: 85px;

      .carousel-control-desc {
        position: absolute;
        top: 19px;
        width: 100%;
        display: block;
        text-align: center;
        font-size: 25.6px;
        color: #0075f2;
      }

    }
    @include respond-to(wide-screens) {
      .carousel-control-desc {
        display: none;
      }
    }
  }
  .carousel-control {
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 1;

    @include respond-to(wide-screens) {
      bottom: 238px;
      svg {
        width: 50px;
        height: 50px;
        background-color: #0079ff;
        cursor: pointer;
        border-radius: 50%;
        transition: background-color .2s;
        &:hover {
          background-color: #0168d9;
        }
      }
      &.left {
        left: 172px;
        transform: rotate(180deg);
      }
      &.right {
        right: 172px;
        //@include transform(rotate(-180deg));
      }
    }

    @include respond-to(handhelds) {
      top: 25px;

      svg {
        width: 32px;
        height: 24px;
      }

      &.left {
        right: 88px;
        transform: rotate(180deg);
      }
      &.right {
        left: 88px;
      }
    }

    .desk-arrow {
      @include respond-to(handhelds) {
        display: none;
      }
      @include respond-to(wide-screens) {
        display: block;
      }
    }
    .mob-arrow {
      @include respond-to(handhelds) {
        display: block;
      }
      @include respond-to(wide-screens) {
        display: none;
      }
    }
  }
}

.fbnew-slider-block {
  font-family: $lato;
  background-image: radial-gradient(circle at 50% 84%, #33c8ff, #0084ff);
  @include background-fix;
  .wrapper1 {
    @include respond-to(handhelds) {
      padding: 46px 0 0;
    }
    @include respond-to(wide-screens) {
      padding-top: 60px;
    }
  }
  .fbnew-slider-title {
    text-align: center;
    font-weight: 900;
    color: #fff;
    @include respond-to(handhelds) {
      font-size: 46px;
      padding: 0 20px 63px;
      letter-spacing: 1px;
    }
    @include respond-to(wide-screens) {
      font-size: 30px;
      letter-spacing: 0.6px;
      margin-bottom: 45px;
    }
  }
  .carousel-indicators {
    display: flex;
    justify-content: space-between;
    @include respond-to(handhelds) {
      padding: 0 40px 125px;
      position: relative;
    }
    @include respond-to(wide-screens) {
      margin: 0 30px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: solid 3px rgba(176,190,197,0.3);
      }
    }
    li {
      display: block;
      text-align: center;
      cursor: pointer;
      @include respond-to(handhelds) {
        width: 107px;
        height: 115px;
        padding-bottom: 14px;
        border-bottom: 7px solid transparent;
        box-sizing: border-box;
      }
      @include respond-to(wide-screens) {
        //height: 36px;
      }
      svg {
        @include respond-to(handhelds) {
          display: inline-block;
          opacity: 0.6;
          transition: .5s ease;
        }
        @include respond-to(wide-screens) {
          display: none;
        }
      }
      .indicator-title {
        text-align: center;
        white-space: nowrap;
        position: relative;
        text-transform: uppercase;
        @include respond-to(handhelds) {
          font-size: 32px;
          font-weight: 900;
          letter-spacing: 0.7px;

          color: transparent;
          padding-top: 38px;
          padding-bottom: 47px;
          position: absolute;

          bottom: 0;
          left: 0;
          width: 100%;
          border-top: 2px solid rgba(255,255,255,0.1);
          word-spacing: 8px;
        }
        @include respond-to(wide-screens) {
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0.3px;
          color: #99CFFF;
          padding-top: 17px;
          padding-bottom: 17px;
          transition: color .3s ease;
        }
      }
      &.active {
        @include respond-to(handhelds) {
          border-bottom-color: #fff;
          .indicator-title {
            color: #fff;
          }
        }
        @include respond-to(wide-screens) {
          svg {
            opacity: 1;
          }
          .indicator-title {
            color: #fff;
            border-bottom: 3px solid #fff;
          }
        }
      }
    }
    .go-slide-ind1 {
      @include respond-to(handhelds) {
        padding-left: 15px;
      }
    }
    .go-slide-ind2 {
      @include respond-to(handhelds) {
        padding-left: 5px;
      }
    }
    .go-slide-ind1 {
      svg {
        @include respond-to(handhelds) {
          width: 72px;
          height: 69px;
        }
      }
    }
    .go-slide-ind2 {
      svg {
        @include respond-to(handhelds) {
          width: 65px;
          height: 69px;
        }
      }
    }
    .go-slide-ind3 {
      svg {
        @include respond-to(handhelds) {
          width: 86px;
          height: 72px;
        }
      }
    }
    .go-slide-ind4 {
      svg {
        @include respond-to(handhelds) {
          width: 99px;
          height: 63px;
        }
      }
    }
  }
  .iphone-wrapper {
    overflow-y: hidden;
    margin: 0 auto;
    position: relative;
    @include respond-to(handhelds) {
      width: 550px;
      height: 920px;
    }
    @include respond-to(wide-screens) {
      width: 367px;
      height: 620px;
      margin-top: 45px;
    }
    .iphone-img {
      display: block;
      @include respond-to(handhelds) {
        width: 550px;
        height: 1145px;
      }
      @include respond-to(wide-screens) {
        width: 367px;
        height: 765px;
      }
    }
    .carousel-inner {
      position: absolute;
      overflow: hidden;
      @include respond-to(handhelds) {
        top: 105px;
        left: 28px;
        width: 492px;
        img {
          width: 492px;
          height: auto;
        }
      }
      @include respond-to(wide-screens) {
        top: 70px;
        left: 18px;
        width: 330px;
        img {
          width: 330px;
          height: auto;
        }
      }
    }
  }
  .carousel-control {
    display: block;
    position: absolute;
    @include respond-to(handhelds) {
      bottom: 356px;
      &.left {
        left: 16px;
        transform: rotate(180deg);
      }
      &.right {
        right: 16px;
      }
    }
    @include respond-to(wide-screens) {
      bottom: 222px;
      opacity: 0.3;
      transition: .5s ease;
      &.left {
        left: 200px;
        transform: rotate(180deg);
      }
      &.right {
        right: 200px;
      }
      &:hover {
        opacity: 1;
      }
    }
    .desk-arrow {
      @include respond-to(handhelds) {
        display: none;
      }
      @include respond-to(handhelds) {
        display: none;
      }
      @include respond-to(wide-screens) {
        display: block;
        width: 70px;
        height: 70px;
        fill: #fff;
      }
    }
    .mob-arrow {
      @include respond-to(handhelds) {
        display: block;
        width: 17px;
        height: 59px;
      }
      @include respond-to(wide-screens) {
        display: none;
      }
    }

  }
}

.hp-new-slider-block {
  background-color: #F7F8FA;
  @include background-fix;
  @include respond-to(wide-screens) {
    position: relative;
    overflow: hidden;
    &:before, &:after {
      content: '';
      position: absolute;
    }
    &:before {
      left: 0;
      top: 0;
      width: 290px;
      height: 581px;
      background: url(./img/slider-bg1.png) no-repeat;
    }
    &:after {
      right: 0;
      bottom: 0;
      width: 364px;
      height: 665px;
      background: url(./img/slider-bg2.png) no-repeat;
    }
  }
  .wrapper1 {
    @include respond-to(handhelds) {
      padding: 76px 0 0;
    }
    @include respond-to(wide-screens) {
      height: 665px;
      position: relative;
    }
  }
  .slider {
    @include respond-to(handhelds) {
    }
    @include respond-to(wide-screens) {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .iphone-wrapper {
    overflow-y: hidden;
    margin: 0 auto;
    position: relative;
    @include respond-to(handhelds) {
      width: 550px;
      height: 908px;
    }
    @include respond-to(wide-screens) {
      width: 367px;
      height: 608px;
    }
    .iphone-img {
      display: block;
      @include respond-to(handhelds) {
        width: 543px;
        height: 1145px;
      }
      @include respond-to(wide-screens) {
        width: 364px;
        height: 765px;
      }
    }
    .carousel-inner {
      position: absolute;
      overflow: hidden;
      @include respond-to(handhelds) {
        top: 105px;
        left: 26px;
        width: 492px;
        img {
          width: 492px;
          height: auto;
        }
      }
      @include respond-to(wide-screens) {
        top: 69px;
        left: 17px;
        width: 330px;
        img {
          width: 330px;
          height: auto;
        }
      }
    }
  }
  .carousel-control {
    display: block;
    position: absolute;
    z-index: 100;
    @include respond-to(handhelds) {
      bottom: 356px;
      &.left {
        left: 16px;
        transform: rotate(180deg);
      }
      &.right {
        right: 16px;
      }
    }
    @include respond-to(wide-screens) {
      bottom: 250px;
      &.left {
        left: 200px;
        transform: rotate(180deg);
      }
      &.right {
        right: 200px;
      }
      &:hover {
      }
    }
    .desk-arrow {
      @include respond-to(wide-screens) {
        display: block;
        width: 70px;
        height: 70px;
        fill: #CBCBD3;
        transition: fill .5s ease;
        &:hover {
          fill: #60BD81;
        }
      }
    }
    .mob-arrow {
      @include respond-to(handhelds) {
        display: block;
        width: 25px;
        height: 68px;
        fill: #000;//???
      }
      @include respond-to(wide-screens) {
        display: none;
      }
    }
  }
  .carousel-indicators {
    @include respond-to(handhelds) {
      position: absolute;
      bottom: -66px;
      width: 100%;
      text-align: center;
      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        &:not(:first-child) {
          margin-left: 5px;
        }
        &.active {
          background: #61B05F;
        }
        &:not(.active) {
          cursor: pointer;
          background: #6B6E7D;
        }
      }
    }
    @include respond-to(wide-screens) {
      display: none;
    }
  }
}


/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
