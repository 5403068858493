@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.press-about-us-block {
  background-color: #fff;
  /*
  .merkle {
    a svg {
      @include respond-to(handhelds) {
        width: 82px;
        height: 43px;
        margin-right: 50px;
      }
      @include respond-to(wide-screens) {
        width: 50px;
        height: 26px;
      }
    }
  }
  .huffpost {
    a svg {
      @include respond-to(handhelds) {
        width: 346px;
        height: 41px;
      }
      @include respond-to(wide-screens) {
        width: 190px;
        height: 25px;
      }
    }
  }
*/ }
  .press-about-us-block .wrapper1 {
    text-align: center;
    position: relative; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .wrapper1 {
        padding-top: 85px;
        padding-bottom: 85px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .wrapper1 {
        padding-top: 40px;
        padding-bottom: 50px; } }
  .press-about-us-block .press-about-us-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #303240; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .press-about-us-title {
        font-size: 47.6px;
        line-height: 1.39; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .press-about-us-title {
        font-size: 28px;
        margin-bottom: 25px; } }
  .press-about-us-block .press-about-us-list {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .press-about-us-list {
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 54.4px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .press-about-us-list {
        justify-content: space-around;
        margin: 0 50px; } }
  @media only screen and (max-width: 1198px) {
    .press-about-us-block .press-about-us-item {
      margin-top: 57.8px; }
      .press-about-us-block .press-about-us-item:last-child {
        margin-left: auto;
        margin-right: auto; } }
  .press-about-us-block .press-about-us-link {
    display: block; }
  .press-about-us-block .press-about-us-logo {
    display: block; }
  .press-about-us-block .forbes-logo {
    fill: #31619C; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .forbes-logo {
        width: 163.2px;
        height: 42.5px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .forbes-logo {
        width: 96px;
        height: 25px; } }
  .press-about-us-block .wired-logo {
    fill: #231F20; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .wired-logo {
        width: 241.4px;
        height: 49.3px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .wired-logo {
        width: 142px;
        height: 29px; } }
  .press-about-us-block .venturebeat-logo {
    fill: #000;
    color: #FF0000; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .venturebeat-logo {
        width: 273.7px;
        height: 35.7px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .venturebeat-logo {
        width: 161px;
        height: 21px; } }
  .press-about-us-block .tech-co-logo {
    fill: #191913;
    color: #8CC833; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .tech-co-logo {
        width: 144.5px;
        height: 47.6px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .tech-co-logo {
        width: 79px;
        height: 26px; } }
  .press-about-us-block .nextmedia-logo {
    fill: #313231; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .nextmedia-logo {
        width: 127.5px;
        height: 62.9px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .nextmedia-logo {
        width: 67px;
        height: 33px; } }
  .press-about-us-block .nulltx-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F0391D; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .nulltx-link {
        width: 85px;
        height: 85px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .nulltx-link {
        width: 50px;
        height: 50px; } }
  .press-about-us-block .nulltx-logo {
    fill: #fff; }
    @media only screen and (max-width: 1198px) {
      .press-about-us-block .nulltx-logo {
        width: 47.6px;
        height: 59.5px; } }
    @media only screen and (min-width: 1199px) {
      .press-about-us-block .nulltx-logo {
        width: 28px;
        height: 35px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
