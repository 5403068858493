@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.partners-blockV4 .wrapper2 {
  text-align: center;
  position: relative; }
  @media only screen and (max-width: 1198px) {
    .partners-blockV4 .wrapper2 {
      padding-top: 82px;
      padding-bottom: 102px; } }
  @media only screen and (min-width: 1199px) {
    .partners-blockV4 .wrapper2 {
      padding-top: 68px;
      padding-bottom: 90px; } }

.partners-blockV4 .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #303240; }
  @media only screen and (max-width: 1198px) {
    .partners-blockV4 .title {
      font-size: 47.8px;
      line-height: 1.39; } }
  @media only screen and (min-width: 1199px) {
    .partners-blockV4 .title {
      font-size: 28px;
      line-height: 1.39; } }

@media only screen and (max-width: 1198px) {
  .partners-blockV4 .partners-list {
    width: 538px;
    height: 279px;
    margin: 50px auto 0;
    background: url("./img/handhelds/partner-mobile.png");
    background-size: cover; } }
  @media only screen and (max-width: 1198px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 1198px) and (min-resolution: 192dpi), only screen and (max-width: 1198px) and (min--moz-device-pixel-ratio: 2), only screen and (max-width: 1198px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 1198px) and (min-device-pixel-ratio: 2), only screen and (max-width: 1198px) and (min-resolution: 2dppx) {
    .partners-blockV4 .partners-list {
      background-image: url("./img/handhelds/partner-mobile@2x.png");
      background-size: cover; } }

@media only screen and (min-width: 1199px) {
  .partners-blockV4 .partners-list {
    width: 977px;
    height: 47px;
    margin: 45px auto 0;
    background: url("./img/wide-screens/partner-desktop@2x.png") 0 0 no-repeat;
    background-size: cover;
    /*
      @include retina(x2) {
        background: url('./img/wide-screens/partner-desktop@2x.png') 0 0 no-repeat;
        background-size: cover;
      }
*/ } }

.partners-blockV4 .personal-nerd-btn {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  color: #fff; }
  @media only screen and (max-width: 1198px) {
    .partners-blockV4 .personal-nerd-btn {
      width: 512px;
      line-height: 76.8px;
      border-radius: 100px;
      margin-top: 43.7px;
      font-size: 30px;
      background-color: #0079ff; } }
  @media only screen and (min-width: 1199px) {
    .partners-blockV4 .personal-nerd-btn {
      width: 220px;
      line-height: 45px;
      border-radius: 100px;
      background-color: #0079ff;
      font-size: 18px; }
      .partners-blockV4 .personal-nerd-btn:hover {
        background-color: #0069d9; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
