$breakpoint: 1198px;
@mixin respond-to($media) {
    @if $media == handhelds {
        @media only screen and (max-width: $breakpoint) { @content; }
    }
    @else if $media == wide-screens {
        @media only screen and (min-width: $breakpoint + 1) { @content; }
    }
}

@mixin background-fix() { // background color fix
    @include respond-to(handhelds) {
        min-width: 640px;
    }
    @include respond-to(wide-screens) {
        min-width: 1000 + 40px;
    }
}

@mixin retina($pixel_ratio) {
    @if $pixel_ratio == x2 {
        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 2dppx) {
            @content;
        }
    }
    @else if $pixel_ratio == x1 {
        @content;
    }
}
