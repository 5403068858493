@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.rate-blockV4 {
  background-color: #fafafc;
  text-align: center; }
  @media only screen and (max-width: 1198px) {
    .rate-blockV4 {
      min-width: 640px; } }
  @media only screen and (min-width: 1199px) {
    .rate-blockV4 {
      min-width: 1040px; } }
  .rate-blockV4 .wrapper2 {
    text-align: center; }
    @media only screen and (max-width: 1198px) {
      .rate-blockV4 .wrapper2 {
        padding-top: 100px;
        padding-bottom: 34.2px; } }
    @media only screen and (min-width: 1199px) {
      .rate-blockV4 .wrapper2 {
        padding-top: 59px;
        padding-bottom: 5px; } }
  .rate-blockV4 .title {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: #00bf5c; }
    @media only screen and (max-width: 1198px) {
      .rate-blockV4 .title {
        font-size: 49.5px;
        line-height: 1.59; } }
    @media only screen and (min-width: 1199px) {
      .rate-blockV4 .title {
        font-size: 32px;
        line-height: 1.44; } }
  .rate-blockV4 .subtitle {
    font-family: "Assistant", sans-serif;
    font-weight: bold;
    line-height: 1.56;
    color: #6c6f80; }
    @media only screen and (max-width: 1198px) {
      .rate-blockV4 .subtitle {
        font-size: 27.3px; } }
    @media only screen and (min-width: 1199px) {
      .rate-blockV4 .subtitle {
        font-size: 16px; } }
  .rate-blockV4 .rating {
    display: inline-block;
    position: relative; }
    .rate-blockV4 .rating img {
      display: block; }
      @media only screen and (max-width: 1198px) {
        .rate-blockV4 .rating img {
          width: 324.3px;
          height: 49.5px;
          margin-top: 30.4px; } }
      @media only screen and (min-width: 1199px) {
        .rate-blockV4 .rating img {
          width: 190px;
          height: 29px;
          margin-top: 18px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
