@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell"), url("/fonts/Rockwell.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell.woff") format("woff"), url("/fonts/Rockwell.ttf") format("truetype"), url("/fonts/Rockwell.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'RockwellBold';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Rockwell-Bold.eot");
  /* IE9 Compat Modes */
  src: local("Rockwell-Bold"), local("RockwellBold"), url("/fonts/Rockwell-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Rockwell-Bold.woff") format("woff"), url("/fonts/Rockwell-Bold.ttf") format("truetype"), url("/fonts/Rockwell-Bold.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.row {
  display: flex;
  flex-flow: row wrap; }
  .row.vertical {
    align-items: center; }
  .row.space-between {
    justify-content: space-between; }
  .row.strech {
    align-items: stretch; }
  .row .right {
    justify-content: flex-end;
    text-align: right; }
  .row > [class^="col-"] {
    padding: 0 10px; }
    .row > [class^="col-"].no-padding {
      padding: 0; }
  .row > [class^="col-"]:first-child {
    padding-left: 0; }
  .row > [class^="col-"]:last-child {
    padding-right: 0; }
  .row > [class^="col-"]:first-child.is-padding {
    padding-left: 10px; }
  .row > [class^="col-"]:last-child.is-padding {
    padding-right: 10px; }
  .row .col-1 {
    flex: 1; }
  .row .col-2 {
    flex: 2; }
  .row .col-3 {
    flex: 3; }
  .row .col-4 {
    flex: 4; }
  .row .col-5 {
    flex: 5; }
  .row .col-6 {
    flex: 6; }
  .row .col-7 {
    flex: 7; }
  .row .col-8 {
    flex: 8; }
  .row .col-9 {
    flex: 9; }
  .row .col-10 {
    flex: 10; }
  .row .col-11 {
    flex: 11; }
  .row .col-12 {
    flex: 12; }
  .row .col-0 {
    flex: 0 1 auto; }

.footer-v4 {
  position: relative;
  border-top: 1px solid #dadce6;
  color: #303240;
  font-family: 'Assistant', sans-serif;
  padding: 50px 0 0; }
  @media only screen and (max-width: 1198px) {
    .footer-v4 {
      min-width: 640px; } }
  @media only screen and (min-width: 1199px) {
    .footer-v4 {
      min-width: 1040px; } }
  .footer-v4 .footer-top {
    line-height: 2.31;
    font-size: 13px;
    padding-bottom: 35px;
    position: relative; }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-top {
        padding-top: 300px; } }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-top p {
        font-size: 26px;
        margin-bottom: 25px; } }
    .footer-v4 .footer-top p.email-box {
      margin-top: 20px; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top p.email-box {
          margin: 0; } }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top p.email-box b {
          display: inline; } }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-top p b {
        display: block;
        line-height: 35px; } }
    .footer-v4 .footer-top .img-strip-box {
      width: calc(100% + 25px);
      position: relative;
      left: -25px;
      margin: 40px 0 35px;
      display: block; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .img-strip-box {
          left: auto;
          margin-top: 0;
          width: 100%;
          position: static; } }
    .footer-v4 .footer-top .img-block {
      text-align: right; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .img-block {
          position: absolute;
          top: 0;
          text-align: center;
          width: 100%; } }
    .footer-v4 .footer-top .footer__payment-list {
      display: grid; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .footer__payment-list {
          display: grid;
          grid-template-columns: repeat(3, min-content);
          margin-bottom: 35px;
          justify-content: center;
          grid-gap: 25.5px 22.1px; } }
      @media only screen and (min-width: 1199px) {
        .footer-v4 .footer-top .footer__payment-list {
          grid-template-columns: repeat(6, min-content);
          margin: 40px 0 35px;
          grid-gap: 15px 13px; } }
    .footer-v4 .footer-top .footer__payment-item {
      display: block; }
    .footer-v4 .footer-top .footer__payment-img {
      display: block;
      width: 54px;
      height: 35px; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .footer__payment-img {
          width: 91.8px;
          height: 59.5px; } }
      @media only screen and (min-width: 1199px) {
        .footer-v4 .footer-top .footer__payment-img {
          width: 54px;
          height: 35px; } }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .footer__payment-img.ae {
          width: 93.5px; } }
      @media only screen and (min-width: 1199px) {
        .footer-v4 .footer-top .footer__payment-img.ae {
          width: 55px; } }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .footer__payment-img.applepay {
          width: 100.3px;
          height: 59.5px; } }
      @media only screen and (min-width: 1199px) {
        .footer-v4 .footer-top .footer__payment-img.applepay {
          width: 59px;
          height: 35px; } }
    .footer-v4 .footer-top .img-mcafee,
    .footer-v4 .footer-top .img-paypal {
      height: 50px; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .img-mcafee,
        .footer-v4 .footer-top .img-paypal {
          height: 70px; } }
    .footer-v4 .footer-top .img-moneyback {
      height: 50px; }
      @media only screen and (max-width: 1198px) {
        .footer-v4 .footer-top .img-moneyback {
          height: 70px; } }
    .footer-v4 .footer-top .img-mcafee {
      margin-left: 20px; }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-top.row {
        display: block; }
        .footer-v4 .footer-top.row > [class^="col-"] {
          padding: 0; }
      .footer-v4 .footer-top .hide-mob {
        display: none; } }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-top .right-col {
        text-align: center; } }
  .footer-v4 .footer-nav {
    font-size: 15px;
    font-weight: 600;
    line-height: 2.47;
    color: #6c6f80; }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-nav {
        font-size: 28px;
        line-height: 2.41;
        border-bottom: 1px solid #dadce6;
        padding: 0 0 35px 35px;
        margin-bottom: 43px; } }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-nav li {
        line-height: 28px;
        margin-bottom: 45px; } }
  .footer-v4 .footer-bottom {
    padding: 20px 0;
    border-top: 1px solid #dadce6;
    font-size: 13px;
    line-height: 2.31; }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-bottom {
        display: block;
        text-align: center;
        font-size: 30px;
        line-height: 48px;
        padding: 33px 0;
        border-top: none; }
        .footer-v4 .footer-bottom .right {
          text-align: center;
          margin-top: 40px;
          padding: 55px 0 0; } }
    .footer-v4 .footer-bottom a:hover {
      color: #0069d9;
      transition: color .2s; }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .footer-bottom a {
        border-bottom: 1px solid #303240; } }
    @media only screen and (min-width: 1199px) {
      .footer-v4 .footer-bottom a {
        text-decoration: underline; } }
  .footer-v4 .social-link {
    position: relative;
    display: inline-block;
    line-height: 20px; }
    @media only screen and (max-width: 1198px) {
      .footer-v4 .social-link {
        border-bottom: none !important; } }
    .footer-v4 .social-link + .social-link {
      padding-left: 30px; }
      .footer-v4 .social-link + .social-link:before {
        content: '\b7\a0';
        font-size: 20px;
        display: inline-block;
        position: absolute;
        background: none;
        top: 0;
        left: 3px;
        margin: 0 5px 0 10px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
